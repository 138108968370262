import Form                             from 'antd/lib/form';
import Select                           from 'antd/lib/select';
import Spin                             from 'antd/lib/spin';
import { observer }                     from 'mobx-react';
import React                            from 'react';
import { appStore }                     from '../../stores';
import { interventionsStore }           from '../../stores';

function InterventionFilter() {
	const { interventionUrnFilter } = appStore;
	const { completedInterventionCollection, nextInterventionCollection } = interventionsStore;

	const isLoading = (
		completedInterventionCollection.isLoading
		|| nextInterventionCollection.isLoading
	);

	if (isLoading) {
		return <Spin />;
	}

	return (
		<Form
			initialValues={{ urn: interventionUrnFilter }}
			key={interventionUrnFilter}
		>
			<Form.Item
				label="Intervention"
				name="urn"
				style={{ width: 512 }}
			>
				<Select
					loading={isLoading}
					onChange={urn => appStore.setInterventionUrnFilter(urn.toString())}
					placeholder="Choisissez une intervention"
				>
					{nextInterventionCollection.length && (
						<Select.OptGroup label="Prochaines interventions">
							{nextInterventionCollection.map(intervention => (
								<Select.Option
									key={intervention.urn}
									value={intervention.urn}
								>
									{intervention.number} : {intervention.displayDate}
								</Select.Option>
							))}
						</Select.OptGroup>
					)}

					{completedInterventionCollection.length && (
						<Select.OptGroup label="Interventions effectuées">
							{completedInterventionCollection.map(intervention => (
								<Select.Option
									key={intervention.urn}
									value={intervention.urn}
								>
									{intervention.number} : {intervention.displayDate}
								</Select.Option>
							))}
						</Select.OptGroup>
					)}
				</Select>
			</Form.Item>
		</Form>
	);
}

export default observer(InterventionFilter);
