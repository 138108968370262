import { AbstractResolvableCollection }             from '@mathquis/modelx-resolvables';
import { Identifier }                               from '@mathquis/modelx-resolvables';

import { ConnectorResults }                         from '@mathquis/modelx';
import { Model }                                    from '@mathquis/modelx';
import _get                                         from 'lodash/get';
import _groupBy                                     from 'lodash/groupBy';

import _orderBy                                     from 'lodash/orderBy';

import { override }                                 from 'mobx';
import { whenAsync }                                from 'tools/modelxTools';
import AbstractApiModel                             from '../models/abstracts/AbstractApiModel';

export type CollectionSorts = Record<string, 'asc' | 'desc'>;

export type SortWay = boolean | null | 'asc' | 'desc';

declare type iteratee<T extends Model> = (model: T, index?: number) => unknown;

export class ApiCollection<T extends AbstractApiModel> extends AbstractResolvableCollection<T> {
	protected _filters: Partial<ModelFilters<T>> = {};
	
	protected _itemsPerPage = 100;
	
	protected _page = 1;
	
	protected _requiredFilters: string[] = [];

	protected _sorts: CollectionSorts = {};

	public clear() {
		this.clearFilters().clearSorts();

		return super.clear();
	}

	public clearFilters() {
		this._filters = {};

		return this;
	}

	public clearModels() {
		return super.clear();
	}

	public clearSorts() {
		this._sorts = {};

		return this;
	}

	public distinctDefinedKey(attribute: keyof T) {
		return this.distinctKey(attribute).filter(v => typeof v !== 'undefined');
	}

	public distinctKey(attribute: keyof T) {
		return [...new Set(this.pluckKey(attribute))];
	}

	public filterBy<KeyName extends keyof T>(key: KeyName, value: T[KeyName]) {
		return this.filter(model => model[key] === value);
	}

	public findBy<KeyName extends keyof T>(key: KeyName, value: T[KeyName]) {
		return this.find(model => model[key] === value);
	}

	public getByIds(ids: number[] | string[] | id[]) {
		return this.filter(m => ids.includes(m.id as never));
	}

	public getFilterValue<FilterName extends ModelFilterName<T>>(name: FilterName) {
		return this._filters[name];
	}

	public getFilters() {
		return this._filters;
	}

	public getSorts() {
		return this._sorts;
	}

	public get hasFilters() {
		return !!Object.keys(this._filters).length;
	}

	public groupBy<KeyName extends keyof T>(
		propertyName: KeyName | KeyName[],
		filterIterator?: (model: T) => boolean,
		orderByIterator?: (model: T) => unknown,
	) {
		let models = filterIterator ? this.filter(filterIterator) : this.models;

		if (orderByIterator) {
			models = _orderBy(models, orderByIterator);
		}

		if (Array.isArray(propertyName)) {
			return _groupBy(models, model => propertyName.map(name => model[name]).join());
		}

		return _groupBy(models, model => model[propertyName]);
	}

	public list(options?: ApiConnectorOptions<T>) {
		this.checkRequiredFilters();
		return super.list(options);
	}

	public async listBy<FilterName extends ModelFilterName<T>>(
		values: ModelFilters<T>[FilterName][],
		filterName: FilterName = 'id' as FilterName,
		options: ApiConnectorOptions<T> = {},
		withFilters?: ModelFilters<T>,
	) {
		if (withFilters) {
			console.warn(`ApiCollection - listBy - withFilters param is used => deprecated - ${this.path}`);

			this.setFilters(withFilters);
		}

		let cleanValues = [...new Set(values as never)].filter(v => typeof v !== 'undefined' && v !== '');

		if (filterName === 'id') {
			cleanValues = cleanValues.filter(id => id); // Empêche de filter sur l'id "0"
		} else {
			console.warn(`ApiCollection - listBy - filter "${String(filterName)}" is used - ${this.path}`);
		}

		this.setFilter(filterName, cleanValues as unknown as ModelFilters<T>[FilterName]);

		if (cleanValues.length) {
			await this.list(options);
		} else {
			this.clearModels();
		}

		return this;
	}

	public async listByFromCollection<M extends AbstractApiModel>(
		collection: ApiCollection<M> | M[],
		attribute: keyof M,
		filterName: ModelFilterName<T> = 'id',
		options: ApiConnectorOptions<T> = {},
		withFilters?: ModelFilters<T>,
	) {
		const values = collection.map(model => _get(model, attribute));

		return this.listBy(values, filterName, options, withFilters);
	}

	// For resolvables
	public async listById(identifiers: Identifier[]): Promise<this> {
		await this.listBy(identifiers, 'id', {
			params: {
				itemsPerPage: identifiers.length,
			},
		});

		// Si un id n'a pas été trouvé dans la requête list
		if (__DEV__) {
			const notFoundIdentifiers = identifiers.filter(id => !this.ids.includes(id as number));

			if (notFoundIdentifiers.length) {
				// On affiche un message avec la liste des entités et identifiants non trouvés
				const notFoundMessage = `Resolvable - listById - ${this.model.name} not found (ID : ${notFoundIdentifiers.join(', ')})`;
				console.error(notFoundMessage);
			}
		}

		return this;
	}
	/**
	 * @deprecated Utiliser listBy ou listByFromCollection
	 */
	public async listIds(
		ids: ModelFilters<T>['id'][],
		options?: ApiConnectorOptions<T>,
	): Promise<this> {
		return this.listBy(ids, 'id', options);
	}

	/**
	 * @deprecated Utiliser le distinctDefinedKey
	 */
	public mapDistinct(iterator: iteratee<T>): never[] {
		return [...new Set(this.map(iterator) as never[])].filter((v: unknown) => typeof v !== 'undefined');
	}

	public pluckKey<KeyName extends keyof T>(attribute: KeyName, defaultValue = undefined): T[KeyName][] {
		return this.map((model) => _get(model, attribute, defaultValue)) as T[KeyName][];
	}

	public removeFilter(field: string): this {
		this._filters = Object.keys(this._filters)
			.filter(key => key !== field)
			.reduce((newFilters, key) => {
				newFilters[key] = this._filters[key];
				return newFilters;
			}, {});

		return this;
	}

	public removeFilters(fields: (ModelFilterName<T>)[]): this {
		this._filters = Object.keys(this._filters)
			.filter((key) => !fields.includes(key as ModelFilterName<T>))
			.reduce((newFilters, key) => {
				newFilters[key] = this._filters[key];
				return newFilters;
			}, {});

		return this;
	}

	public setFilter<FilterName extends ModelFilterName<T>>(
		name: FilterName,
		value: ModelFilters<T>[FilterName],
	): this {
		this._filters[name] = value;

		return this;
	}

	public setFilters(filters: ModelFilters<T>): this {
		this._filters = { ...filters };

		return this;
	}

	public setRequiredFilter<FilterName extends ModelFilterName<T>>(name: FilterName, value: ModelFilters<T>[FilterName]) {
		this._requiredFilters.push(name as string);
		return this.setFilter(name, value);
	}
	
	public get ids(): T['id'][] {
		return this.map(m => m.id);
	}

	public get urns(): T['urn'][] {
		return this.map(m => m.urn);
	}

	public setSort(field: string, way: SortWay = true): this {
		if (field) {
			switch (typeof way) {
				case 'boolean':
					this._sorts[`order[${field}]`] = way ? 'asc' : 'desc';
					break;
				case 'object':
					delete this._sorts[`order[${field}]`];
					break;
				default:
					this._sorts[`order[${field}]`] = way;
			}
		}

		return this;
	}

	public setSorts(sorts: Record<string, SortWay>): this {
		this._sorts = {};

		Object.keys(sorts).forEach(key => this.setSort(key, sorts[key]));

		return this;
	}

	public whenIsLoaded = (iter: (m: T) => AbstractApiModel = m => m) => whenAsync(() => {
		return this.isLoaded && this.every(m => iter(m).isLoaded);
	});

	protected checkRequiredFilter(value: unknown) {
		return value !== undefined && value !== null && value !== '';
	}

	protected checkRequiredFilters() {
		for (const filter of this._requiredFilters) {
			if (
				!this.checkRequiredFilter(this._filters[filter])
				|| (
					typeof this._filters[filter] === 'object' 
					&& !Object.values(this._filters[filter] || {}).some(this.checkRequiredFilter)
				)
			) {
				throw new Error(`Filer ${filter.toString()} empty`);
			}
		}
	}

	@override
	protected onListError(err: Error) {
		try {
			this.onError('Error listing models', err);
		} catch (error) {
			// bypass
		}

		// to bypass throw new CollectionError(message + ' -> ' + err.message, err); in onError
		throw err;
	}


	@override
	protected onListSuccess(results: ConnectorResults, options: ApiConnectorOptions<T>): void {
		this.onSuccess(results);

		const newModels = results.items.map((item) => this.createModel(item, {
			collection: this,
			loaded: true,
			transform: true,
		}));

		if (options.pushResults) {
			this.set([...this.models, ...newModels]);
		} else {
			this.set(newModels);
		}

		this.isLoaded = true;
	}

	// eslint-disable-next-line @typescript-eslint/ban-types
	protected prepareListOptions(options): object {
		const superOptions = {
			...options,
			params: {
				itemsPerPage: this._itemsPerPage,
				page: this._page,
				pagination: true,
				...options.params,
				...this._filters,
				...this._sorts,
			},
		};


		return super.prepareListOptions(superOptions);
	}
}
