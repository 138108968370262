import { EllipsisOutlined }                  from '@ant-design/icons';
import InterventionModel                     from 'Models/intervention/InterventionModel';
import Button                                from 'antd/lib/button';
import Dropdown                              from 'antd/lib/dropdown';
import { Page }                              from 'components/Page';
import { useNavigate }                       from 'react-router-dom';
import React                                 from 'react';
import { appStore, interventionsStore }      from 'stores/index';
import AbstractInterventionList              from '../../../entityComponents/Intervention/AbstractInterventionList';

export default function InterventionListFuturePage() {
	const navigate = useNavigate();
	return (
		<Page title="Prochaines interventions">
			<AbstractInterventionList
				cardProps={{
					title: 'Prochaines interventions',
				}}
				collection={interventionsStore.nextInterventionCollection}
				customColumns={(defaultColumns => [
					...defaultColumns,
					{
						align: 'right',
						fixed: 'right',
						render: (v, intervention: InterventionModel) => {
							return (
								<Dropdown menu={{
									items: [
										{
											key: 'files',
											label: 'Consulter les documents',
											onClick: () => {
												appStore.setInterventionUrnFilter(intervention.urn);
												navigate('/files');
											},
										},
									]
								}} trigger={['click']}>
									<Button style={{ border: 'none', padding: 0 }}>
										<EllipsisOutlined style={{ fontSize: 20 }} />
									</Button>
								</Dropdown>
							);
						},
						title: '',
					}
				])}
			/>
		</Page>
	);
}
